<script>
	import { page } from '$app/stores';
	import LogoPng from '$lib/assets/images/logo-transparent.png';
	import { serializeSchema } from '$lib/utils/helpers';

	export let title = 'London Tradition';
	export let description =
		'London Tradition is a British heritage brand that specialises in the design and manufacture of premium outerwear, all expertly made in London.';
	export let image = LogoPng;
	export let schema = {
		'@context': 'https://schema.org',
		'@type': 'WebSite',
		url: $page.url.href,
		name: title,
		description: description
	};

	const truncate = (str, chars) => {
		if (str.length > chars) {
			return str.slice(0, chars) + '...';
		}
		return str;
	};
</script>

<svelte:head>
	<title>{title}</title>
	<meta name="description" content={truncate(description, 160)} />

	<!-- Open Graph meta tags -->
	<meta property="og:title" content={title} />
	<meta property="og:description" content={truncate(description, 160)} />
	<meta property="og:image" content={image} />
	<meta property="og:url" content={$page.url.href} />
	<meta property="og:type" content="website" />
	<meta property="og:site_name" content="London Tradition" />

	<!-- Twitter meta tags -->
	<meta name="twitter:title" content={title} />
	<meta name="twitter:description" content={truncate(description, 160)} />
	<meta name="twitter:image" content={image} />
	<meta name="twitter:card" content="summary_large_image" />

	{@html serializeSchema(schema)}
</svelte:head>
